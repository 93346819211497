import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <p>{`The text input and its variations.`}</p>
    <p>{`Basically it's a HTML `}<inlineCode parentName="p">{`input`}</inlineCode>{` element with `}<inlineCode parentName="p">{`input`}</inlineCode>{` class. So all attributes it's comming for a regular HTML element.`}</p>
    <h2 {...{
      "id": "basic-example"
    }}>{`Basic Example`}</h2>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='input-text-1'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <input className='display-block input' type='text' name='input-text' id='input-text-1' placeholder='Placeholder' />
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='input-text'>
      Label
    </label>
    <div class='wrapper-input mb-3'>
      <input
        class='display-block input'
        type='text'
        name='input-text'
        id='input-text'
        placeholder='Placeholder'
      />
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "labeled"
    }}>{`Labeled`}</h2>
    <p>{`Place a label or button in any side of an input. You may also place on both sides. Inside `}<inlineCode parentName="p">{`wrapper-input`}</inlineCode>{` just add a label or button with `}<inlineCode parentName="p">{`input-group-text`}</inlineCode>{`.`}</p>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='input-text-icon-3'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <span className='input-group-text'>
        R$
      </span>
      <input className='display-block input' type='number' name='input-text-icon-3' id='input-text-icon-3' placeholder='XX' />
      <button className='button button-pink input-group-text'>
        <i className="fas fa-check"></i>
      </button>
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-sm-24 col-md-12 col-lg-8'>
    <label class='display-block' for='input-text-icon-4'>
      Label
    </label>
    <div class='wrapper-input mb-3'>
      <span
        class='input-group-text'
      >
        R$
      </span>
      <input
        class='display-block input'
        type='number'
        name='input-text-icon-4'
        id='input-text-icon-4'
        placeholder='XX'
      />
      <button
        class='button button-pink input-group-text'
      >
        <i class="fas fa-check"></i>
      </button>
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "error"
    }}>{`Error`}</h2>
    <p>{`An input field can show the data contains errors. Add `}<inlineCode parentName="p">{`error`}</inlineCode>{` class on input and a element with `}<inlineCode parentName="p">{`box-validation box-invalid`}</inlineCode>{` below `}<inlineCode parentName="p">{`wrapper-input`}</inlineCode>{` in case of error message is need it.`}</p>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='input-text-error'>
      Label
    </label>
    <div className='wrapper-input'>
      <input className='display-block input error' type='text' name='input-text-error' id='input-text-error' placeholder='Placeholder' required />
    </div>
    <div className='box-validation box-invalid show mb-3'>
      <i className="fas fa-exclamation-triangle"></i>Error
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='input-text-error'>
      Label
    </label>
    <div class='wrapper-input'>
      <input
        class='display-block input error'
        type='text'
        name='input-text-error'
        id='input-text-error'
        placeholder='Placeholder'
        required
      />
    </div>
    <div class='box-validation box-invalid show mb-3'>
      <i class="fas fa-exclamation-triangle"></i>Error
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "valid"
    }}>{`Valid`}</h2>
    <p>{`An input field can show that it is valid. Add `}<inlineCode parentName="p">{`valid`}</inlineCode>{` class on input and a element with `}<inlineCode parentName="p">{`box-validation box-valid`}</inlineCode>{` below `}<inlineCode parentName="p">{`wrapper-input`}</inlineCode>{` in case of a valid message is need it.`}</p>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='input-text-valid'>
      Label
    </label>
    <div className='wrapper-input'>
      <input className='display-block input valid' type='text' name='input-text-valid' id='input-text-valid' placeholder='Placeholder' />
    </div>
    <div className='box-validation box-valid show mb-3'>
      <i className="fas fa-check-circle"></i>Valid
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='input-text-valid'>
      Label
    </label>
    <div class='wrapper-input'>
      <input
        class='display-block input valid'
        type='text'
        name='input-text-valid'
        id='input-text-valid'
        placeholder='Placeholder'
      />
    </div>
    <div class='box-validation box-valid show mb-3'>
      <i class="fas fa-check-circle"></i>Valid
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "readonly"
    }}>{`Readonly`}</h2>
    <p>{`An input field can show that it is readonly. Add `}<inlineCode parentName="p">{`readonly`}</inlineCode>{` attribute/class on input.`}</p>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='input-text-readonly'>
      Label
    </label>
    <div className='wrapper-input'>
      <input type="text" className="display-block input" name="input-text-readonly" id="input-text-readonly" value="Readonly field" placeholder="Placeholder" readOnly />
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='input-text'>
      Label
    </label>
    <div class='wrapper-input'>
      <input
        type="text"
        class="display-block input"
        name="input-text-readonly"
        id="input-text-readonly"
        value="Readonly field"
        placeholder="Placeholder"
        readonly
      />
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <p>{`An input field can show that it is disabled. Add `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` attribute/class on input.`}</p>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='input-text-disabled'>
      Label
    </label>
    <div className='wrapper-input'>
      <input className='display-block input' type='text' name='input-text-disabled' id='input-text-disabled' placeholder='Placeholder' disabled />
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='input-text'>
      Label
    </label>
    <div class='wrapper-input'>
      <input
        class='display-block input'
        type='text'
        name='input-text'
        id='input-text'
        placeholder='Placeholder'
        disabled
      />
    </div>
  </div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      